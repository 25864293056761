<template>
	<Modal
		:id="id"
		:is-open="isModalOpen(id)"
		class="dark:text-white"
		:max-width="maxWidth"
		:override-controls="overrideControls"
		:request-loading="requestLoading"
		hide-close
		@on-confirm="$emit('confirm')"
		@on-cancel="$emit('cancel')"
		@on-close="$emit('close')"
	>
		<template #title>
			<div class="flex justify-center w-full">
				<ExclamationTriangleIcon class="h-24 text-mx-orange" />
			</div>
			<h4 class="w-full text-2xl text-center">
				{{ title }}
			</h4>
		</template>

		<template #body>
			<div
				:class="[
					'flex flex-col w-full mx-auto py-4 px-2',
					bodyClasses
				]"
			>
				<div
					v-if="description"
					class="dark:text-mx-gray-300"
					v-html="description"
				/>
				<slot />
			</div>
		</template>
	</Modal>
</template>

<script setup lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import { useModal } from '@/composables/useModal'

defineProps({
	id: {
		type: String,
		required: true
	},
	title: {
		type: String,
		default: ''
	},
	description: {
		type: String,
		default: ''
	},
	warning: {
		type: String,
		default: ''
	},
	maxWidth: {
		type: String,
		default: 'max-w-lg'
	},
	bodyClasses: {
		type: String,
		default: 'text-center justify-center'
	},
	overrideControls: {
		type: Boolean,
		default: false
	},
	requestLoading: {
		type: Boolean,
		default: false
	}
})

const { isModalOpen } = useModal()

defineEmits([ 'confirm', 'cancel', 'close' ])
</script>
